<div class="container">
    <div class="row row-spacing">
        <div class="col-md-4 offset-md-4">
            <div class="text-center">
                <p>
                    <img src="assets/img/Logo-tec.png" width="200" alt="">
                </p>
                <h3>Ups!</h3>
                <h2>404 No encontrado</h2>
                <div class="error-details mb-3">
                    Lo siento, no se encontró la página solicitada!
                </div>
                <div class="error-actions">
                    <a routerLink="/inicio" class="btn btn-danger btn-lg">
                        Regresar a inicio
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
