import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './_guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    loadChildren: () => import('./web-apps/web-apps.module').then(m => m.WebAppsModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./web-admin/web-admin.module').then(m => m.WebAdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'NotFound',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'NotFound'
  }
];
